import { useLocation, useNavigate } from '@remix-run/react';
import { useCallback, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { useAppAnalytics } from '../../analytics/app/identifiable';
import { useQueryParam } from '../../hooks/useQueryParam';
import { CloseIcon } from '../icons/CloseIcon';
import { SearchIcon } from '../icons/SearchIcon';

export interface QueryBindSearchBarProps {
  targetPathname: string;
  placeholder?: string;
}

export function QueryBindSearchBar(
  props: QueryBindSearchBarProps
): JSX.Element {
  const q = useQueryParam('q') || '';
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (val: string) => {
    const trimmed = val.trim();
    const params = new URLSearchParams(location.search);
    params.set('q', trimmed);
    if (q === trimmed) return;
    navigate({ pathname: props.targetPathname, search: params.toString() });
  };

  return (
    <SearchBarV2
      defaultValue={q}
      onChange={handleChange}
      bgClassName='bg-black'
      placeholder={props.placeholder}
    />
  );
}

export function SearchBarV2(props: {
  defaultValue?: string | null;
  onChange?: (val: string) => void;
  bgClassName?: string;
  iconClassName?: string;
  placeholder?: string;
}): JSX.Element {
  const [value, setValue] = useState<string | null>(props.defaultValue || null);

  const handleValueChange = () => {
    if (value === null) return;
    props.onChange && props.onChange(value);
  };

  useDebounce(handleValueChange, 500, [value]);

  return (
    <RawSearchBarV2
      value={value}
      setValue={setValue}
      bgClassName={props.bgClassName}
      iconClassName={props.iconClassName}
      placeholder={props.placeholder}
    />
  );
}

export function RawSearchBarV2(props: {
  value: string | null;
  setValue: (v: string | null) => void;
  bgClassName?: string;
  iconClassName?: string;
  placeholder?: string;
}): JSX.Element {
  const { value, setValue } = props;

  return (
    <div className='flex relative items-center'>
      <input
        className={`h-10 w-40 lg:w-50 xl:w-60 px-4 text-sms 2xl:text-sm border border-secondary rounded-xl outline-none appearance-none caret-color-primary text-white ${
          props.bgClassName ?? 'bg-black bg-transparent'
        }`}
        value={value || ''}
        onChange={(event) => setValue(event.target.value)}
        placeholder={props.placeholder || 'Search'}
      />
      <div
        className={`flex items-center justify-center text-secondary w-7.5 h-7.5 absolute  ${
          props.iconClassName ?? 'right-2.5 rounded-lg'
        }`}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

/**
 * Note(falcon): this is a bit of a clone of SearchBarV2 and RawSearchBarV2. While we could provide a prop to make
 * those search bars collapsible, it felt cleaner to introduce a new component, given the design and use case is
 * distinct from how those search bars are currently used.
 */
export function CollapsibleSearchBar(props: {
  onChange: (v: string) => void;
}): JSX.Element {
  const appAnalytics = useAppAnalytics();
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [collapsed, setCollapsed] = useState(true);

  const notifyValueChange = () => {
    if (value) {
      appAnalytics.trackSearchQueried(value);
    }
    props.onChange(value);
  };
  useDebounce(notifyValueChange, 500, [value]);

  const handleToggle = useCallback(() => {
    if (collapsed) {
      setCollapsed(false);
      inputRef.current?.focus();
    } else if (value) {
      handleCancel();
    }
  }, [collapsed, value]);

  const handleCancel = () => {
    setValue('');
    setCollapsed(true);
  };

  const handleBlur = () => {
    if (value) return;
    setCollapsed(true);
  };

  const width = collapsed ? 'w-10' : 'w-60 lg:w-70 xl:w-80';
  return (
    <div
      className={`
        ${width} h-10 
        transition-all
        rounded-xl
        overflow-hidden
        border
        ${collapsed ? 'border-transparent' : 'border-secondary'}
      `}
    >
      <div className='h-full flex flex-row-reverse items-center bg-transparent'>
        <button
          type='button'
          className={`
            flex-none focus:outline-none
            cursor-pointer
            bg-transparent
            w-10 h-10
            flex items-center justify-center
            text-white
            ${!collapsed && !value ? 'pointer-events-none' : ''}
          `}
          onClick={handleToggle}
        >
          {!collapsed && value ? (
            <CloseIcon className='w-3 h-3 active:w-4 active:h-4 transition-size fill-current' />
          ) : (
            <SearchIcon className='w-4 h-4 active:w-5 active:h-5 transition-size fill-current' />
          )}
        </button>

        <div className={`${collapsed ? 'w-0' : ''} flex-growshrinkmin px-4`}>
          <input
            ref={inputRef}
            className='h-10 w-full rounded-xl outline-none appearance-none caret-color-primary text-white bg-transparent text-sm'
            value={value || ''}
            onChange={(event) => setValue(event.target.value)}
            placeholder='Search'
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
}
